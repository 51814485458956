<template>
    <div>
        <articlesList />
    </div>
</template>

<script>
import articlesList from '@/components/admin/articles/articlesList'
export default {
    name: "AdminArticles",
    components: { articlesList },
    data: () => ({

    })
};
</script>
